const VNFlag = '/images/vi.png';
const USFlag = '/images/en.png';
const AppStoreIcon = '/images/appStoreIcon.svg';
const PlayStoreIcon = '/images/playStoreIcon.svg';

export const ZALO_LINK = 'https://zalo.me/4580689828120920371';
export const TELIO_APPSTORE_LINK =
  'https://apps.apple.com/vn/app/telio-nh%E1%BA%ADp-h%C3%A0ng-gi%C3%A1-t%E1%BB%91t/id1472497039';
export const TELIO_PLAYSTORE_LINK =
  'https://play.google.com/store/apps/details?id=vn.telio&hl=en&gl=US';
export const BOOKS_APPSTORE_LINK =
  'https://apps.apple.com/vn/app/teliobooks/id1514738260?l=vi';
export const BOOKS_PLAYSTORE_LINK =
  'https://play.google.com/store/apps/details?id=vn.telio.book&hl=vi&gl=US';
export const TELIO_CAREERS = 'https://hiring.telio.vn/';
export const MESSENGER_LINK =
  'https://www.facebook.com/messages/t/1089320521237965';
export const COUNTER_LOTTIE_EN =
  'https://assets8.lottiefiles.com/private_files/lf30_ntktv6kt.json';
export const COUNTER_LOTTIE_VI =
  'https://assets6.lottiefiles.com/private_files/lf30_zwg5ynaz.json';
export const COUNTER_LOTTIE_VI_MOBILE =
  'https://assets4.lottiefiles.com/private_files/lf30_r8mjg2dd.json';
export const COUNTER_LOTTIE_EN_MOBILE =
  'https://assets1.lottiefiles.com/private_files/lf30_lbj6hkhy.json';
export const TELIO_CERTIFIED_GOV_LINK1 =
  'http://online.gov.vn/Home/WebDetails/64365';
export const TELIO_CERTIFIED_GOV_LINK2 =
  'http://online.gov.vn/Home/WebDetails/63380';

export const DELIVERY_POLICY = `https://www.telio.vn/Delivery-Policy.pdf`;
export const PAYMENT_POLICY = `https://www.telio.vn/Payment-Policy.pdf`;
export const RETAILERS_POLICY = `https://www.telio.vn/Retailers-Policy.pdf`;
export const RETURNING_POLICY = `https://www.telio.vn/Returning-Policy.pdf`;
export const SECURITY_POLICY = `https://www.telio.vn/Security-Policy.pdf`;

export const languages = [
  { value: 'en', label: 'language.english', icon: USFlag },
  { value: 'vi', label: 'language.vietnamese', icon: VNFlag },
];

export const stores = [
  { value: 'Android', label: 'zaloApp.forAndroid', icon: PlayStoreIcon },
  { value: 'iOS', label: 'zaloApp.forIOS', icon: AppStoreIcon },
];

export const COUNTRY_CODE = '+84';

export const TIME_ZONE = {
  VIETNAM: 'Asia/Ho_Chi_Minh',
};
