import {
  BOOKS_APPSTORE_LINK,
  BOOKS_PLAYSTORE_LINK,
  TELIO_APPSTORE_LINK,
  TELIO_PLAYSTORE_LINK,
  ZALO_LINK,
} from '../constants';

export const openInNewTab = (url: string) => {
  window.open(url, '_blank');
};

export const openTelioZalo = () => {
  openInNewTab(ZALO_LINK);
};

export const openTelioBooksAppStore = () => {
  openInNewTab(BOOKS_APPSTORE_LINK);
};

export const openTelioBooksPlayStore = () => {
  openInNewTab(BOOKS_PLAYSTORE_LINK);
};

export const openTelioAppAppStore = () => {
  openInNewTab(TELIO_APPSTORE_LINK);
};

export const openTelioAppPlayStore = () => {
  openInNewTab(TELIO_PLAYSTORE_LINK);
};
