import Link from 'next/link';
import React, { LegacyRef } from 'react';

// `onClick`, `href`, and `ref` need to be passed to the DOM element
// for proper handling
// eslint-disable-next-line react/display-name
const ChildComponent = React.forwardRef(
  ({ onClick, children }: any, ref: LegacyRef<HTMLDivElement> | undefined) => {
    return (
      <div style={{ cursor: 'pointer' }} onClick={onClick} ref={ref}>
        {children}
      </div>
    );
  }
);

function CustomeLink({ children, href, disable, locale }: any) {
  if (disable) {
    return <div>{children}</div>;
  }
  return (
    <Link href={href} passHref scroll={false} locale={locale}>
      <ChildComponent>{children}</ChildComponent>
    </Link>
  );
}

export default CustomeLink;
